import React from 'react';
import Testimonial from 'shared/testimonial';

export default function TestimonialTemplate({ background_color, image, ...props }) {
  return (
    <section>
      <Testimonial
        {...{ background: background_color, image: image.title, ...props }}
        customAuthor
      />
    </section>
  );
}
