const axiosInstance = require('axios');

const url = process.env.GATSBY_WORDPRESS_API;
// wordpress axios instance
const axios = axiosInstance.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  }
});

// export function to call wp api
module.exports = async function fetchWordpress(params) {
  try {
    const { data } = await axios.get(params);
    return data;
  } catch (e) {
    throw e;
  }
};
