const fetchWordpress = require('../api/wordpress');

async function fetchWordpressByPage(type, segment, offset, data = [], idx = 1) {
  try {
    const query = `/${type}?per_page=${segment}&order=asc${
      offset ? `&offset=${offset}` : ''
    }`;
    const fetchData = await fetchWordpress(query);
    if (fetchData[0]) {
      const accumulatedData = [...data, { page: idx, data: fetchData }];
      return await fetchWordpressByPage(
        type,
        segment,
        offset ? offset + segment : segment,
        accumulatedData,
        idx + 1
      );
    }
    return data;
  } catch (e) {
    throw e;
  }
}

async function fetchAllWordpress(type, offset, data = []) {
  try {
    const query = `/${type}?per_page=100&order=asc${
      offset ? `&offset=${offset}` : ''
    }`;
    const fetchData = await fetchWordpress(query);
    if (fetchData[0]) {
      const accumulatedData = [...data, ...fetchData];
      return await fetchAllWordpress(
        type,
        offset ? offset + 100 : 100,
        accumulatedData
      );
    }
    return data;
  } catch (e) {
    throw e;
  }
}

async function fetchClientPagesWP() {
  try {
    const query = '/pages?filter[meta_key]=page_type&filter[meta_value]=client';
    const pages = await fetchWordpress(query);
    return pages;
  } catch (e) {
    throw e;
  }
}

module.exports = {
  fetchWordpressByPage: async (type, segment) =>
    fetchWordpressByPage(type, segment),
  fetchAllWordpress: async type => fetchAllWordpress(type),
  fetchClientPagesWP: async type => fetchClientPagesWP(type),
};
