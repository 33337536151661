function pageData(page) {
  if (!page) return {};
  const { acf, id } = page;
  const { page_type, slug, featured } = acf;
  const templates = Object.keys(acf)
    .filter(type => Array.isArray(acf[type]))
    .map(template => acf[template])
    .reduce((acc, template) => (template ? [...acc, ...template] : acc), []);
  return {
    slug,
    featured,
    id,
    page_type,
    meta: {
      title: acf.title,
      description: acf.description,
      canonical: acf.canonical,
      ogimage: acf.ogimage,
    },
    templates,
  };
}

module.exports = {
  pageData
};
