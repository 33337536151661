import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import Button from 'shared/popup-button';
import s from './message-banner.module.scss';

export default function MessageBanner({
  text,
  button,
  background_image,
  ...props
}) {
  return (
    <Container className={s.root}>
      <div className={s.overlay} />
      <div className={s.imgWrapper}>
        <Img
          className={s.img}
          src={background_image.title}
          alt={background_image.title}
        />
      </div>
      <div className={s.content}>
        <Headline className={s.text} h1 dash center white el="p" html={text} />
        {Object.keys(button).length > 0 && (
          <div className={s.buttonWrapper}>
            <Button
              popupName="small-form"
              className={s.button}
              {...button}
            >
              {button.text}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
}
