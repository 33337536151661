import React from 'react';
import Container from 'container';
import Img from 'img';
import s from './half-text-image-overlap.module.scss';

export default function TextImageSplitOverlap({ text, image, id, ...props }) {
  return (
    <Container className={s.root} idx={id}>
      <div className={s.wrapper}>
        <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
        <div className={s.imgWrapper}>
          <Img className={s.img} src={image.title} alt={image.title} />
        </div>
      </div>
    </Container>
  );
}
