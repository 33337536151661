import React from 'react';
import Container from 'container';
import Img from 'img';
import s from './half-text-image.module.scss';

export default function HalfTextImage({ text, image,id, ...props }) {
  return (
    <Container className={s.root} id={id}>
      <div className={s.wrapper}>
        <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
        <div className={s.imgWrapper}>
          <Img className={s.img} src={image} alt={image} />
        </div>
      </div>
    </Container>
  );
}
