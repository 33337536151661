import React from 'react';
import LetsStart from 'shared/lets-start';

export default function CTABanner({ button, ...props }) {
return (
  <LetsStart
    {...button}
  />
);
}
