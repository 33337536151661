import React from 'react';
import Container from 'container';
import Img from 'img';
import s from './lets-tell.module.scss';

export default function LetsTell({ background_image, ...props }) {
  return (
    <Container className={s.root}>
      <div className={s.imageSection}>
        <div className={s.imgWrapper}>
          <Img
            className={s.imgBackground}
            src={background_image.title || 'cxn-beach2x'}
            alt={background_image.title || 'cxn-beach2x'}
          />
        </div>
        <img
          className={s.contentImage}
          src="/svg/cxn-story-together.svg"
          alt="lets tell your story together"
        />
      </div>
    </Container>
  );
}
