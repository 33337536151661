import React from 'react';
import LoadMoreGrid from 'shared/load-more-grid';

export default function RelatedWork({ title, featuredList, ...props }) {
  let cards;
  try {
    cards = featuredList.map(({ featured, slug }) => ({
      project_name: featured.title,
      services_provided: featured.services_provided,
      image: featured.large_image,
      href: slug
    })).filter(card => window !== undefined && window && !card.href.includes(window.location.pathname.replace(/\/$/, '')));
  } catch (error) {
    cards = featuredList.map(({ featured, slug }) => ({
      project_name: featured.title,
      services_provided: featured.services_provided,
      image: featured.large_image,
      href: slug
    }));
  }

  return (
    <LoadMoreGrid
      {...{
        headline: title,
        cards,
        maxCards: 3,
      }}
    />
  );
}
