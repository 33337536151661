import React from 'react';
import Meta from 'meta';

export default function WordpressBody(props) {
  const { templates, slug, meta, page_type, ...otherProps } = props;
  const children = templates
    .map(({ acf_fc_layout, ...componentProps }, idx) => {
      try {
        const Component = require(`components/templates-${page_type}/${acf_fc_layout}`).default;
        return (
          <Component
            {...componentProps}
            {...otherProps}
            id={idx}
            key={`${acf_fc_layout}-${idx}`}
          />
        );
      } catch (e) {
        return null;
      }
    })
    .filter(component => component);
  return (
    <>
      <Meta fromWP {...meta} />
      {children}
    </>
  );
}
