import React from 'react';
import Container from 'container';
import Headline from 'headline';
import s from './half.module.scss';

export default function HalfTitleText({ title, text,id, ...props }) {
  return (
    <Container className={s.root} id={id}>
      <div className={s.wrapper}>
        <Headline center bold dash className={s.title} >
          {title}
        </Headline>
        <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </Container>
  );
}
