import React from 'react';
import Img from 'img';
import getTag from 'utils/get-tag';
import TitleSubtitle from 'shared/title-subtitle';
import s from './image-and-text.module.scss';

export default function ImageAndText({
  title,
  subtitle,
  text,
  image,
  ...props
}) {
  const TextTag = getTag(text);
  return (
    <section className={s.root}>
      <div className={s.textwrapper}>
        <TitleSubtitle className={s.title} {...{ title, subtitle }} />
        <TextTag
          className={s.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
      <div className={s.imgWrapper}>
        <Img className={s.img} src={image.title} alt={image.title} />
      </div>
    </section>
  );
}
