import React, { useEffect, useState } from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import Button from 'button';
import { navigate, Link } from 'gatsby';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import s from './load-more-grid.module.scss';

export default function RelatedWork(compProps) {
  const { cards, headline, id, maxCards, ...props } = compProps;
  const topMax = cards.length;
  useWhyDidYouUpdate('Counter', compProps);
  const [max, setMax] = useState(2);
  const [loadedMore, setLoadedMore] = useState(false);
  const [width, setWidth] = useState(0);

  function handleClick() {
    if (width > 767) setMax(max + 3);
    else setMax(max + 2);
    setLoadedMore(true);
  }

  function find3(num) {
    while (num % 3 !== 0) {
      num++;
    }
    return num;
  }

  function find2(num) {
    while (num % 2 !== 0) {
      num++;
    }
    return num;
  }
  function updateWidth() {
    setWidth(window.innerWidth);
  }

  const updateHeight = () => {
    if (loadedMore) {
      if (width > 767) setMax(max % 3 === 0 ? max : find3(max));
      else if (width < 767) setMax(max % 2 === 0 ? max : find2(max));
    } else if (width > 767) setMax(3);
    else if (width < 767) setMax(2);
  };

  useEffect(() => {
    updateHeight();
  }, [width]);

  useEffect(() => {
    updateWidth();
    updateHeight();
    window && window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const handleEnter = () => console.log('HELLO');

  return (
    <Container className={s.root} id={id}>
      {headline && (
        <Headline h1 className={s.title}>
          {headline}
        </Headline>
      )}
      <ul className={`${s.projectsWrapper} ${topMax < 3 ? s.few : ''}`}>
        {cards
          .filter((el, i) => i < max && (maxCards ? i < maxCards : true))
          .map(({ image, project_name, services_provided, href }, i) => (
            <li
              className={`${s.work} ${topMax < 3 ? s.few : ''}`}
              key={`${project_name}-${i}`}
              onClick={() => navigate(href)}
            >
              <div className={s.blur} />
              <Img className={s.img} src={image.title} alt={image.title} />
              <div className={s.info}>
                <Link to={href}>
                  <Headline h2 el="p" className={s.projectName}>
                    {project_name}
                  </Headline>
                </Link>
                <Headline h3 el="p" className={s.servicesProvided}>
                  {services_provided}
                </Headline>
              </div>
            </li>
          ))}
      </ul>
      {max < topMax && max < maxCards && (
        <div className={s.buttonWrapper}>
          <Button onClick={() => handleClick()}>LOAD MORE</Button>
        </div>
      )}
    </Container>
  );
}
