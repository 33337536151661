import React from 'react';
import OurWork from 'shared/our-work';

export default function OurWorkSubService({ featuredList, ...props }) {
  const cards = featuredList.map(({ featured, slug }) => ({
    title: featured.title,
    subtitle: featured.services_provided,
    small_image: featured.small_image,
    large_image: featured.large_image,
    href: slug
  }));
return (
  <OurWork {...{ cards, ...props }} />
);
}
