import React from 'react';
import Container from 'container';
import Img from 'img';
import Icon from 'icon';
import useSetScrollRef from 'hooks/useSetScrollRef';
import Headline from 'headline';
import { navigate } from 'gatsby';
import Button from 'shared/popup-button';
import SmallForm from 'shared/small-form';
import s from './hero-subservice.module.scss';

export default function HeroSubservice({
  top_text,
  title,
  text,
  button,
  image,
  ...props
}) {
  const hero = useSetScrollRef();
  const buttonProps = {
    href: button.href,
    popup_trigger: button.popup_trigger
  };
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={s.imgWrapper}  >
          <Img className={s.img} src={image.title} alt={image.title} />
        </div>
        <div className={s.overlay} ref={hero}/>
        <div className={s.content}>
          <div
            className={s.backWrapper}
            onClick={() => navigate('/what-we-do/')}
          >
            <Icon icon="angle-right" />
            <span className={s.back}>BACK TO SERVICES</span>
          </div>
          <div className={s.textWrapper}>
            <Headline h1 primary className={s.topText}>
              {top_text}
            </Headline>
            <Headline white h1 bold className={s.title}>
              {title}
            </Headline>
            <Headline white h3 el="p" bold className={s.text}>
              {text}
            </Headline>
            <div className={s.buttonWrapper}>
              <Button
                {...buttonProps}
                popupName="small-form"
                className={s.button}
              >
                {button.text}
              </Button>
            </div>
          </div>
          <div className={s.formWrapper}>
            <SmallForm
              title="GET IN TOUCH!"
              subtitle="Don’t know how to start? We can help."
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
