import React from 'react';
import Img from 'img';
import s from './brief.module.scss';

export default function LargeImageComponent({ image, id, ...props }) {
  return (
    <section id={id}>
      <Img className={s.img} src={image.title} alt={image.title} />
    </section>
  );
}
