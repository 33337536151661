import React from 'react';
import Container from 'container';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'shared/popup-button';
import TitleSubtitle from 'shared/title-subtitle';
import ProgressRing from 'shared/progress-ring';
import s from './text-and-rings.module.scss';

export default function TextAndRings({
  title,
  subtitle,
  text,
  button,
  rings_title,
  rings,
  ...props
}) {
  const TextTag = getTag(text);
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={s.ringsWrapper}>
          <Headline h3 bold className={s.ringsTitle}>
            {rings_title}
          </Headline>
          {rings.map(({ max_value, actual_value, text, ...rest }, i) => (
            <div key={`ring-${i}`} className={s.ringCard}>
              <div className={s.ringCardWrapper}>
                <div className={s.ring}>
                  <ProgressRing
                    progress={actual_value}
                    max={max_value}
                    {...rest}
                    radius="60"
                    stroke="10"
                  />
                </div>
              </div>
              <p className={s.ringText}>{text}</p>
            </div>
          ))}
        </div>
        <div className={s.textWrapper}>
          <TitleSubtitle className={s.title} {...{ title, subtitle }} />
          <TextTag
            className={s.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <div className={s.buttonWrapper}>
            {Object.keys(button).length > 0 && (
              <Button popupName="small-form" {...button} />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
