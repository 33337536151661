import React, { useEffect, useRef, memo } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { navigate } from '@reach/router';

import s from './loading.module.scss';

export default memo(({ error, url, keepLock }) => {
  const root = useRef();
  useEffect(() => {
    if (error) {
      navigate(url);
    }
  }, [error, url]);

  useEffect(() => {
    disableBodyScroll(root.current);
    return keepLock ? () => null : () => clearAllBodyScrollLocks();
  }, []);

  return (
    <div className={s.root} ref={root}>
      <div className={s.wrapper}>
        <div className={s.spinner}>
          <div className={s.dot1} />
          <div className={s.dot2} />
        </div>
      </div>
    </div>
  );
});
