import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import Button from 'button';
import Icon from 'icon';
import useSetScrollRef from 'hooks/useSetScrollRef';

import s from './hero-client.module.scss';

export default function HeroClient({
  top_text,
  scroll_button,
  title,
  subtitle,
  image,
  color1,
  color2,
  color3,
  idx,
  ...props
}) {
  const hero = useSetScrollRef();
  return (
    <div ref={hero} id={idx}>
      <Container className={s.root}>
        <div className={s.overlay} />
        <div className={s.imgWrapper}>
          <Img className={s.img} src={image.title} alt={image.title} />
        </div>
        <div className={s.content}>
          <Headline h1 primary className={s.topTitle}>
            {top_text}
          </Headline>
          <Headline h1 white className={s.title}>
            {title}
          </Headline>
          <p className={s.text}>{subtitle}</p>
          <div className={s.buttonWrapper}>
            <Button hoverWhite scroll="2">{scroll_button}</Button>
          </div>
        </div>
      </Container>
      <section className={`${s.colors}`}>
        <div className="container">
          <div
            className={`${s.color} ${s.first} ${
              color1 === '#ffffff' ? s.iswhite : ''
            }`}
            style={{ background: color1 }}
          >
            <Icon icon="paint" />
            <span>{color1}</span>
          </div>
          <div
            className={`${s.color} ${s.second} ${
              color2 === '#ffffff' ? s.iswhite : ''
            }`}
            style={{ background: color2 }}
          >
            <span>{color2}</span>
          </div>
          <div
            className={`${s.color} ${s.third} ${
              color3 === '#ffffff' ? s.iswhite : ''
            }`}
            style={{ background: color3 }}
          >
            <span>{color3}</span>
          </div>
        </div>
      </section>
    </div>
  );
}
