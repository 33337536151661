import React from 'react';
import Headline from 'headline';
import s from './title-subtitle.module.scss';

export default function TitleSubtitle({ title, subtitle, className, dash,...props }) {
return (
  <div className={`${className || ''} ${s.titleSubtitleWrapper}`}>
    <Headline h2 small dash={dash}>
      {subtitle}
    </Headline>
    <Headline h1 className={s.title}>
      {title}
    </Headline>
  </div>
);
}
