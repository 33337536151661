import { useState, useEffect } from 'react';
import mapData from 'utils/map-acf';
import cbs from 'utils/shared-callbacks';
import fetchWordpress from '../../api/wordpress';

export default function useWordpress({ id, blog, callbacks }) {
  const handleCallbacks = async () => {
    const pendingData = callbacks.map(async cb => {
      const params = typeof cb === 'object' ? cb.params : null;
      return params ? cbs[cb.cb](cb.params) : cbs[cb]();
    });
    const data = await Promise.all(pendingData);
    return data.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  };
  const [pageData, setPageData] = useState(null);
  const callWordpress = async () => {
    const callbackData = callbacks ? await handleCallbacks() : {};
    const data = id ? await fetchWordpress(`${blog ? 'posts' : 'pages'}/${id}`) : null;
    const wpData = blog ? mapData.blogData(data) : mapData.pageData(data);
    setPageData({ ...wpData, ...callbackData });
  };
  useEffect(() => {
    callWordpress();
  }, []);
  return pageData;
}
