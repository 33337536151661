import React from 'react';
import LetsTell from 'shared/lets-tell';

export default function LetsTellStoryWrapper({ background_image, ...props }) {
return (
  <LetsTell
    background_image={background_image}
  />
);
}
