import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Icon from 'icon';
import getTag from 'utils/get-tag';
import TitleSubtitle from 'shared/title-subtitle';
import s from './text-and-list.module.scss';

export default function TextAndList({ title, subtitle, text, list, ...props }) {
  const TextTag = getTag(text);
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={s.textWrapper}>
          <TitleSubtitle className={s.title} {...{ title, subtitle }} />
          <TextTag
            className={s.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        <ul className={s.list}>
          <Headline h3 bold className={s.listTitle}>
            {list && list.title}
          </Headline>
          {list.items.map(({ text }) => (
            <Headline h3 el="li" className={s.listItem}>
              <span className={s.checkIcon}>
                <Icon icon="check" />
              </span>{' '}
              <span>{text}</span>
            </Headline>
          ))}
        </ul>
      </div>
    </Container>
  );
}
