import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import Icon from 'icon';
import s from './circle.module.scss';

export default function ProgressRing({
  radius,
  stroke,
  progress,
  max,
  custom_value,
  className,
  ...props
}) {
  const [finalProgress, setFinalProgress] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const [opacity, setOpacity] = useState(false);
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference - ((max - finalProgress) / max) * circumference;
  const init = () => {
    setFinalProgress(progress);
    setOpacity(true);
  };
  const getInnerWidth = () => {
    if (window.innerWidth < 768) setTrigger(50);
    else if (window.innerWidth > 767 && window.innerWidth < 1200)
      setTrigger(100);
    else if (window.innerWidth > 1199) setTrigger(250);
  };
  return (
    <div
      className={`${className || ''} ${s.ringWrapper}`}
      style={{ height: radius * 2, width: radius * 2 }}
    >
      <span className={`${s.number} ${opacity ? s.opacity : ''}`}>
        {!custom_value.value && finalProgress}
        {custom_value.icon ? (
          <Icon icon={custom_value.value || 'icon'} className={s.icon} />
        ) : (
          custom_value.value || '%'
        )}
      </span>
      <svg height={radius * 2} width={radius * 2} className={s.svg}>
        <circle
          className={s.circle}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={1000}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <svg
        height={radius * 2}
        width={radius * 2}
        className={`${s.svg} ${s.progress}`}
      >
        <circle
          className={s.circle}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <Waypoint onEnter={getInnerWidth} />
      <Waypoint onEnter={init} bottomOffset={`${trigger}px`} />
    </div>
  );
}
