import React from 'react';
import HalfTitleText from 'shared/half-title-text';
import ProgressRings from './progress-rings';

export default function TheResults({ title,
  text, id,...props }) {
  return (
    <>
      <HalfTitleText {...{ title, text, id }} />
      <ProgressRings {...props} />
    </>
  );
}
