import React, { useState } from 'react';
import Container from 'container';
import Headline from 'headline';
import ProgressRing from 'shared/progress-ring';
import s from './progress-rings.module.scss';

export default function ProgressRings({ rings, ...props }) {
  return (
    <Container className={s.root}>
      <div className={s.ringsWrapper}>
        {rings.map(({ max_value, current_value, title, custom_value }) => (
          <div className={s.ringWrapper} key={title}>
            <ProgressRing
              className={s.ring}
              radius="80"
              stroke="14"
              progress={current_value}
              max={max_value}
              custom_value={custom_value}
            />
            <Headline className={s.title}>{title}</Headline>
          </div>
        ))}
      </div>
    </Container>
  );
}
