const { fetchWordpressByPage, fetchAllWordpress } = require('./fetch-wp');
const mapData = require('./map-acf');

const postPerPage = 12;

async function getPopularPosts() {
  const data = await fetchAllWordpress('posts');
  return {
    popularPosts: data.map(page => mapData.blogData(page))
  };
}

async function getAllCategories() {
  const data = await fetchAllWordpress('categories');
  return {
    categories: data
      .filter(cat => cat.id > 1)
      .map(({ name, slug, id }) => ({
        name,
        slug,
        id
      }))
  };
}

async function gridData({ page: pg, category }) {
  const route = category ? `posts?categories=${category.id}` : 'posts';
  const mappedPosts = await fetchWordpressByPage(route, postPerPage);
  const allCategories = await getAllCategories();
  const postsByPageNumber = mappedPosts
    .map(post => {
      const { page, data } = post;
      const posts = data.map(currentPost => {
        const props = mapData.blogData(currentPost);
        return {
          ...props,
          categories: allCategories.categories,
          page
        };
      });
      return {
        title: category ? category.name : 'Blog',
        totalPages: mappedPosts.length,
        page,
        data: posts
      };
    })
    .filter(post => post.page === pg);
  return postsByPageNumber[0];
}

module.exports = {
  getPopularPosts,
  getAllCategories,
  gridData
};
