import React from 'react';
import Container from 'container';
import Img from 'img';
import Icon from 'icon';
import Headline from 'headline';
import Button from 'button';
import { Link } from 'gatsby';
import s from './what-we-did.module.scss';

export default function WhatWeDid({
  services_provided,
  headline,
  title,
  text,
  client_website,
  button_text,
  image,
  id,
  ...props
}) {
  const fixedServices = [
    'development',
    'branding',
    'strategy',
    'web + app',
    'design + branding',
    'marketing',
    'content',
    'api development'
  ];

  function decideRedirect(service) {
    switch (service.toLowerCase()) {
      case 'development':
      case 'api development':
        return 'development/';

      case 'branding':
      case 'design + branding':
        return 'design-and-branding/';

      case 'web + app':
        return 'web-app/';

      case 'marketing':
      case 'strategy':
        return 'digital-marketing/';
      case 'content':
        return 'content-marketing/';
      case 'advertising':
        return 'advertising/';
      default:
        return 'what-we-do/';
    }
  }
  return (
    <>
      <Container className={s.root} id={id}>
        <div className={s.wrapper}>
          <div className={s.flex}>
            <div className={s.block}>
              <Headline className={s.blockTitle} bold h1>
                {headline}
              </Headline>
              <ul className={s.list}>
                {services_provided.map(({ service }) => (
                  <li className={s.listItem} key={service}>
                    <Link
                      to={`/${decideRedirect(service)}`}
                    >
                      <Icon
                        icon={service
                          .toLowerCase()
                          .replace('web + app', 'web-app')
                          .replace('design + branding', 'branding')
                          .replace('marketing', 'digital')
                          .replace('api development', 'development')}
                      />
                      {service}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={s.block}>
              <Headline className={s.blockTitle} bold h1>
                {title}
                {/* HEADLINE
                <br />
                ABOUT CLIENT */}
              </Headline>
              <p className={s.block2Text}>{text}</p>
              <div className={s.buttonWrapper}>
                <Button
                  className={s.button}
                  native
                  href={client_website}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {button_text}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={s.imgWrapper}>
          <Img className={s.img} src={image.title} alt={image.title} />
        </div>
      </Container>
    </>
  );
}
